<template>
    <div
        v-tooltip="{ classes: 'VTooltip--dark', content: 'Data was imported via API.' }"
        class="inline-flex text-eidSecureBlue-50 items-center gap-2 text-xs"
        data-test-id="DocumentDataOnlyBadge"
    >
        <EidIcon
            :icon-src="tableIcon"
            alt="Data Only"
        />
        <span>Data Only</span>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import tableIcon from '@/assets/icons/table-icon.svg';

    export default Vue.extend({
        name: 'DocumentDataOnlyBadge',
        components: { EidIcon },
        data() {
            return {
                tableIcon,
            };
        },
    });
</script>
