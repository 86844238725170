import { ComplianceStatus, TprmRequirementType, DeclineReason } from '../entity-details';
import { SubmissionMethod } from '../common/SubmissionMethod.model';

export interface VerificationRequest {
    verificationId: string;
    requestId: string;
    insuredId: string;
    createdAt: string;
    completedAt: string | null;
    inputReceivedAt: string | null;
    reasons: { verificationType: ComplianceStatus, coverageType: TprmRequirementType }[];
    cancelledCoverageTypes: TprmRequirementType[];
    extractedCoverageTypes: TprmRequirementType[];
    rprSummary: string | null;
    rprDescription: string | null;
    verificationType: string;
    parentId: string | null;
    verificationRequestStatus: string;
    createdAutomatically: boolean;
    timeoutAt: string | null;
    issuedAt: string;
    declinedCoverageTypes: Record<TprmRequirementType, DeclineReason>;
    submissionMethod?: SubmissionMethod;
}

export interface VerificationRequestConfiguration {
    summaryTemplate: string;
    descriptionTemplate: string;
    initEnabled: boolean;
    remindersEnabled: boolean;
}

export enum VerificationRequestType {
    expiring = 'EXPIRING',
    new = 'NEW',
    nonCompliant = 'NON_COMPLIANT',
}
