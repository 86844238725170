<template>
    <div class="CollateralMappingTable min-w-[800px]">
        <Table>
            <template #head>
                <HeadCell>Required Collateral</HeadCell>
                <HeadCell>Provided Collateral</HeadCell>
                <HeadCell />
                <HeadCell />
            </template>
            <CollateralMappingTableRow
                v-for="(item, i) in collateralAndOptions"
                :key="`${item.collateralEntity.description}-${item.collateralEntity.uniqueIdentifier}`"
                :collateral-entity="item.collateralEntity"
                :provided-collaterals="item.options"
                :no-mapping-icon="isAllAutoMapped"
                :is-no-match="isNoMatch(item.collateralEntity.evaluatedEntityId)"
                :options-based-on-action="item.optionsBasedOnAction"
                :submission-method="getSubmissionMethod(item.collateralEntity)"
                @show-document="$emit('show-document',$event)"
                @update-collateral="updateCollateral($event, i)"
                @update-no-match="$emit('update-no-match', $event)"
            />
        </Table>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import { CollateralEntity } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralEntity.model';
    import { CollateralMappingOptions } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralMappingOptions.model';
    import CollateralMappingTableRow
        from '@/modules/entity-details/components/CollateralMappingTableRow/CollateralMappingTableRow.vue';
    import { ConciseCollateral } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/ConciseCollateral.model';
    import {
        getCollateralMappingOptionStatus,
    } from '@/modules/entity-details/utils/getCollateralMappingOptionStatus/getCollateralMappingOptionStatus';
    import { CollateralMappingStatus } from '@/modules/entity-details/models/CollateralMappingStatus.model';
    import { EntityRequirementDetails } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { SubmissionMethod } from '@evidentid/tprm-portal-lib/models/common/SubmissionMethod.model';

    export default Vue.extend({
        name: 'CollateralMappingTable',
        components: {
            Table,
            HeadCell,
            CollateralMappingTableRow,
        },
        props: {
            collateralEntities: {
                type: Array as PropType<CollateralEntity[]>,
                default: () => [] as CollateralEntity[],
            },
            collateralMappingOptions: {
                type: Array as PropType<CollateralMappingOptions[]>,
                default: () => [] as CollateralMappingOptions[],
            },
            noMatchList: {
                type: Array as PropType<string[]>,
                default: () => [] as string[],
            },
            entityRequirementDetailsList: {
                type: Array as PropType<EntityRequirementDetails[]>,
                default: () => [] as EntityRequirementDetails[],
            },
        },
        computed: {
            collateralAndOptions(): {
                collateralEntity: CollateralEntity;
                options: ConciseCollateral[];
                optionsBasedOnAction: boolean;
            }[] {
                return this.collateralEntities.map((collateralEntity) => {
                    const options = this.collateralMappingOptions
                        .find(
                            (option) => option.evaluatedEntityId === collateralEntity.evaluatedEntityId,
                        );

                    return {
                        collateralEntity,
                        options: options?.options || [],
                        optionsBasedOnAction: options?.optionsBasedOnAction || false,
                    };
                });
            },
            isAllAutoMapped(): boolean {
                return this.collateralAndOptions.every((item) =>
                    getCollateralMappingOptionStatus(
                        item.options, item.collateralEntity,
                    ) === CollateralMappingStatus.auto && !item.optionsBasedOnAction,
                );
            },
        },
        methods: {
            updateCollateral(collateralEntity: CollateralEntity, index: number): void {
                const modifiedEntities = [
                    ...this.collateralEntities.slice(0, index),
                    collateralEntity,
                    ...this.collateralEntities.slice(index + 1),
                ];
                this.$emit('update-collaterals', modifiedEntities);
            },
            isNoMatch(evaluatedEntityId: string): boolean {
                return this.noMatchList?.includes(evaluatedEntityId) || false;
            },
            getSubmissionMethod(collateral: CollateralEntity): SubmissionMethod | null {
                const details = this.entityRequirementDetailsList
                    .find((detail) => detail.coverageType === collateral.coverageType);
                return details?.coverage?.submissionMethod ?? null;
            },
        },
    });
</script>
